import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Pagination from 'react-js-pagination';

import PaginationLoader from 'components/loaders/PaginationLoader';
import { RETIRED_EMAIL_PREFIX } from 'config';
import { usersActions } from 'pages/users/containers/usersContainer/actions';
import AddUser from 'pages/users/containers/usersContainer/components/addUser';
import BulkUser from 'pages/users/containers/usersContainer/components/bulkUser';
import EditUser from 'pages/users/containers/usersContainer/components/editUser';
import UsersListingHeader from 'pages/users/containers/usersContainer/components/usersListingHeader';
import UserListItem from 'pages/users/containers/usersContainer/components/userListItem';
import UserFilters from 'pages/users/containers/usersContainer/components/filters/userFilters';
import { getSiteUsers, getUserSettings } from 'selectors';

const UsersListing = (props) => {
  const {
    current_page,
    users_info,
    validation_messages,
    dispatch,
    setBulkModalShow,
    bulkModalShow,
    setAddModalShow,
    addModalShow,
    count,
    username,
    password_reset,
    is_loading
  } = props;

  const [selectedUsers, setSelectedUsers] = useState({});
  const [checkAll, setCheckAll] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [user, setUser] = useState({});
  const [timer, setTimer] = useState(null);
  const [search, setSearch] = useState(props.search);
  const [roles, setRoles] = useState(props.roles);
  const [pageSize, setPageSize] = useState(10);
  const [userFilters, setUserFilters] = useState({
    column: 'profile__name',
    direction: 'asc'
  });
  const [message, setMessage] = useState('');
  const [inputFile, setInputFile] = useState(false);
  const [progressStart, setProgressStart] = useState(false);

  const handleSelectedUsers = (event) => {
    let selectedUserName = event.target.value;
    let status = event.target.checked;
    setSelectedUsers({
      ...selectedUsers,
      [selectedUserName]: status
    });
    setCheckAll(false);
  };

  const handlePageChange = (page_number) => {
    setPaginationLoading(true);
    let order_by = `${userFilters.column},${userFilters.direction}`;
    dispatch(
      usersActions.fetchUsers({
        page_number,
        roles,
        setPaginationLoading,
        searchText: search,
        page_size: pageSize,
        order_by: order_by
      })
    );
    setCheckAll(false);
  };

  const handleSelectAllUsers = (event) => {
    let status = event.target.checked;
    let users = {};
    users_info.forEach(function(user) {
      if (!user.email.startsWith(RETIRED_EMAIL_PREFIX)) {
        users[user.username] = status;
      }
    });
    setSelectedUsers(users);
    setCheckAll(status);
  };

  const handlePasswordReset = (email) => {
    dispatch(usersActions.resetUserPassword(email));
  };

  const onSort = (columnName) => {
    const direction = userFilters.column ? (userFilters.direction === 'asc' ? 'desc' : 'asc') : 'desc';
    setUserFilters({
      ...userFilters,
      column: columnName,
      direction: direction
    });

    let order_by = `${columnName},${direction}`;
    setTimer(
      setTimeout(() => {
        dispatch(usersActions.fetchUsers({ searchText: search, roles, order_by: order_by, page_size: pageSize }));
      }, 400)
    );
  };

  const usersListItems = users_info.map((user, index) => {
    return (
      <UserListItem
        key={index}
        user={user}
        userFilters={userFilters}
        username={username}
        setSelectedUsers={setSelectedUsers}
        handlePasswordReset={handlePasswordReset}
        handleSelectedUsers={handleSelectedUsers}
        selectedUsers={selectedUsers}
        setUser={setUser}
        setEditModalShow={setEditModalShow}
        passwordReset={password_reset}
      />
    );
  });

  return (
    <Fragment>
      <div className="table-header">
        <UserFilters
          current_page={current_page}
          search={search}
          roles={roles}
          userFilters={userFilters}
          timer={timer}
          setRoles={setRoles}
          setTimer={setTimer}
          setSelectedUsers={setSelectedUsers}
          setSearch={setSearch}
          selectedUsers={selectedUsers}
          users_info={users_info}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setCheckAll={setCheckAll}
          dispatch={dispatch}
        />
      </div>
      <div className="management-table-holder">
        <table className="table">
          <UsersListingHeader
            checkAll={checkAll}
            handleSelectAllUsers={handleSelectAllUsers}
            userFilters={userFilters}
            onSort={onSort}
          />
          <tbody>
            {paginationLoading || is_loading ? (
              <tr>
                <td colSpan={8}>
                  <PaginationLoader />
                </td>
              </tr>
            ) : (
              usersListItems
            )}
          </tbody>
        </table>
      </div>

      <div className="table-footer">
        <Pagination
          activePage={current_page}
          itemsCountPerPage={pageSize}
          totalItemsCount={count}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
        />
      </div>

      <BulkUser
        dispatch={dispatch}
        show={bulkModalShow}
        inputFile={inputFile}
        setInputFile={setInputFile}
        message={message}
        setMessage={setMessage}
        progressStart={progressStart}
        setProgressStart={setProgressStart}
        onHide={() => {
          setBulkModalShow(false);
          setMessage('');
          setInputFile(null);
          setProgressStart(false);
        }}
        size="md"
      />
      <AddUser
        dispatch={dispatch}
        show={addModalShow}
        page={current_page}
        searchText={search}
        roles={roles}
        validation_messages={validation_messages}
        searchreset={() => setSearch('')}
        onHide={() => {
          setAddModalShow(false);
          dispatch(usersActions.validateUser({}));
        }}
        size="md"
      />
      <EditUser
        dispatch={dispatch}
        user={user}
        show={editModalShow}
        page={current_page}
        searchText={search}
        roles={roles}
        validation_messages={validation_messages}
        searchreset={() => setSearch('')}
        onHide={() => {
          setEditModalShow(false);
          dispatch(usersActions.validateUser({}));
        }}
        password_reset={password_reset}
        size="md"
      />
    </Fragment>
  );
};

function mapStateToProps(state) {
  const { username, password_reset } = getUserSettings(state);
  const { num_pages, current_page, count, users_info, search, roles, is_loading } = getSiteUsers(state);
  const { validation_messages } = state.USER_VALIDATION;
  return {
    num_pages,
    current_page,
    count,
    users_info,
    validation_messages,
    username,
    search,
    roles,
    password_reset,
    is_loading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(usersActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersListing);
