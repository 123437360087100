export const siteDetailsConstants = {
  FETCH_SITE_DETAILS_SUCCESS: 'FETCH_SITE_DETAILS_SUCCESS',
  FETCH_SITE_DETAILS_FAILURE: 'FETCH_SITE_DETAILS_FAILURE',
  POST_SITE_DETAILS_REQUEST: 'POST_SITE_DETAILS_REQUEST',
  POST_SITE_DETAILS_SUCCESS: 'POST_SITE_DETAILS_SUCCESS',
  POST_SITE_DETAILS_FAILURE: 'POST_SITE_DETAILS_FAILURE',
  FETCH_TRIAL_USER_SUCCESS: 'FETCH_TRIAL_USER_SUCCESS',
  FETCH_TRIAL_USER_FAILURE: 'FETCH_TRIAL_USER_FAILURE',
  UPDATE_CLIENT_SETUP_SUCCESS: 'UPDATE_CLIENT_SETUP_SUCCESS',
  UPDATE_CLIENT_SETUP_FAILURE: 'UPDATE_CLIENT_SETUP_FAILURE',
  FETCH_JOB_STATE_SUCCESS: 'FETCH_JOB_STATE_SUCCESS',
  FETCH_JOB_STATE_FAILURE: 'FETCH_JOB_STATE_FAILURE',
  LOADING: 'loading',
  FAILURE: 'failure'
};
export const BILLING_PAGE = '/panel/settings/billing';

const fetchSiteDetailsSuccess = (data) => {
  return {
    type: siteDetailsConstants.FETCH_SITE_DETAILS_SUCCESS,
    payload: data
  };
};

const fetchSiteDetailsFailure = (error) => {
  return {
    type: siteDetailsConstants.FETCH_SITE_DETAILS_FAILURE,
    payload: error
  };
};

const postSiteDetailsRequest = () => {
  return {
    type: siteDetailsConstants.POST_SITE_DETAILS_REQUEST
  };
};

const postSiteDetailsSuccess = (data) => {
  return {
    type: siteDetailsConstants.POST_SITE_DETAILS_SUCCESS,
    payload: data
  };
};

const postSiteDetailsFailure = (error) => {
  return {
    type: siteDetailsConstants.POST_SITE_DETAILS_FAILURE,
    payload: error
  };
};

const fetchTrialUserSuccess = (data) => {
  return {
    type: siteDetailsConstants.FETCH_TRIAL_USER_SUCCESS,
    payload: data
  };
};

const fetchTrialUserFailure = (error) => {
  return {
    type: siteDetailsConstants.FETCH_TRIAL_USER_FAILURE,
    payload: error
  };
};

const updateClientSetupSuccess = () => {
  return {
    type: siteDetailsConstants.UPDATE_CLIENT_SETUP_SUCCESS
  };
};

const updateClientSetupFailure = () => {
  return {
    type: siteDetailsConstants.UPDATE_CLIENT_SETUP_FAILURE
  };
};

const fetchJobStateSuccess = (data) => {
  return {
    type: siteDetailsConstants.FETCH_JOB_STATE_SUCCESS,
    payload: data
  };
};

const fetchJobStateFailure = () => {
  return {
    type: siteDetailsConstants.FETCH_JOB_STATE_FAILURE
  };
};

export const siteDetailsDispatches = {
  fetchSiteDetailsSuccess,
  fetchSiteDetailsFailure,
  postSiteDetailsSuccess,
  postSiteDetailsRequest,
  postSiteDetailsFailure,
  fetchTrialUserSuccess,
  fetchTrialUserFailure,
  updateClientSetupSuccess,
  updateClientSetupFailure,
  fetchJobStateSuccess,
  fetchJobStateFailure
};
