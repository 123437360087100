import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getOrganization, getUserPanelRole } from 'selectors';
import { showUserGuideActions } from 'pages/dashboard/containers/displayDemo/actions';
import DemoModel from 'components/demo';
import 'pages/dashboard/index.scss';

const UserGuide = (props) => {
  const { showUserGuide, updateShowUserGuide } = props;
  const [isOpen, setIsOpen] = useState(showUserGuide);

  const handleTermsSubmit = (e) => {
    setIsOpen(false);
    updateShowUserGuide(false);
  };

  return <>{isOpen && <DemoModel isOpen={isOpen} handleTermsSubmit={handleTermsSubmit} />}</>;
};

function mapStateToProps(state) {
  let show_user_guide = getOrganization(state).show_user_guide;
  const panel_role = getUserPanelRole(state);
  const showUserGuide = show_user_guide === true && (panel_role === 'panel_admin' || panel_role === 'super_admin');
  return { showUserGuide };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ ...showUserGuideActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserGuide);
