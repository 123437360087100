import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SiteDetailsForm from './siteDetailsForm';
import SiteDetailsValues from './siteDetailsValues';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

const CustomSiteDetails = ({ siteUrl, email, showSave, loading, isTrialUser, showError }) => {
  const [showSiteValues, setShowSiteValues] = useState(false);

  useEffect(() => {
    if (!isTrialUser && siteUrl && email && !loading && !showSave) {
      setShowSiteValues(true);
    } else {
      setShowSiteValues(false);
    }
  }, [isTrialUser, siteUrl, email, loading, showSave]);

  const edit = () => {
    setShowSiteValues(false);
  };

  return (
    <div className="col-12 col-lg-10">
      <div className="site-details-holder">
        <div className="integration-holder">
          <h1>Site Details </h1>
          <div className="subheader-holder">
            <h3>Site name and address </h3>
            <p>
              Site name is used in the email that are sent to the users, meta tags, login and registration pages. Your
              site address is used to access your learning content.
            </p>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <label className="form-label">
                Site name
                <span className="tool-tip ml-10">
                  <FontAwesomeIcon icon={Icons.faInfoCircle} />
                  <span className="tip-content">This feature is not availabe right now.</span>
                </span>
              </label>
              <input className="form-control" placeholder={'ab courses'} disabled={true}></input>
            </div>

            <div className="col-12 btn-holder justify-content-end d-flex">
              <button className="btn btn-primary ml-2" disabled={true}>
                Save
              </button>
            </div>
          </div>
        </div>
        <div className="integration-holder">
          <div className="subheader-holder">
            <h3>Custom site URL </h3>
            <p>This is the address to access your Edly site.</p>
            <div className="site-btns-holder">
              <button className="btn btn-link site-detail-btn hidden">Check how to do DNS setup</button>
              <button
                className="btn btn-link site-detail-btn"
                onClick={() => window.HubSpotConversations.widget.open()}
              >
                Contact us if you need assistance
              </button>
            </div>
            <div className="alert2">
              <FontAwesomeIcon icon={Icons.faExclamationTriangle} />
              <span>Please do not use edx, open edx words in your domain names to avoid copyright issues</span>
            </div>
          </div>
          {showError && (
            <div class="error-box">
              <p class="error-text">
                There is an issue with your DNS entries, please recheck them with your DNS provider and try again after
                30 minutes.
              </p>
            </div>
          )}

          {!showSiteValues && <SiteDetailsForm />}
          {showSiteValues && <SiteDetailsValues siteUrl={siteUrl} email={email} edit={edit} />}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { siteUrl, email, showSave, loading, showError } = state.SITE_DETAILS;
  const { isTrialUser } = state.FREE_TRIAL;
  return {
    siteUrl,
    email,
    showSave,
    loading,
    isTrialUser,
    showError
  };
}
export default connect(mapStateToProps, null)(CustomSiteDetails);
