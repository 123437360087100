import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const SiteDetailsValues = ({ siteUrl, email, edit }) => {
  return (
    <>
      <h4>Site url details</h4>
      <div className="site-card">
        <div className="url-details">
          <FontAwesomeIcon icon={Icons.faGlobe} className="site-details-icon" />
          <span className="ml-5">{siteUrl}</span>
        </div>
        <div className="url-details">
          <FontAwesomeIcon icon={Icons.faEnvelope} className="site-details-icon" />
          <span>{email}</span>
        </div>
        <div className="btn-holder">
          <button className="btn pen-button" onClick={edit}>
            <FontAwesomeIcon icon={Icons.faPen} className="justify-content-end" />
          </button>
        </div>
      </div>
    </>
  );
};

export default SiteDetailsValues;
