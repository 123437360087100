import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import LearnersAnalytics from 'pages/insights/containers/learnersAnalyticsContainer';
import LearnerDetails from 'pages/insights/containers/learnerDetailContainer';
import CoursesAnalytics from 'pages/insights/containers/coursesAnalyticsContainer';
import CourseDetail from 'pages/insights/containers/courseDetailContainer';
import { analyticsSummaryActions } from 'pages/insights/containers/analyticsSummaryContainer/actions';
import { getUserPanelRole } from 'selectors';
import 'pages/insights/index.scss';

const Insights = (props) => {
  const { panel_role, fetchGeneralSiteMetrics } = props;
  let { path } = useRouteMatch();

  useEffect(() => {
    fetchGeneralSiteMetrics();
  }, [fetchGeneralSiteMetrics]);

  if (panel_role !== 'super_admin' && panel_role !== 'panel_admin' && panel_role !== 'insights_admin') {
    return <Redirect to={{ pathname: '/panel' }} />;
  }

  return (
    <div className="insights-content-container">
      <Switch>
        <Route exact path="/panel/insights">
          <Redirect to={`${path}/learners`} />
        </Route>
        <Route exact path={`${path}/learners`}>
          <LearnersAnalytics />
        </Route>
        <Route exact path={`${path}/courses`}>
          <CoursesAnalytics />
        </Route>
        <Route exact path={`${path}/learners/learner-details/:username`}>
          <LearnerDetails />
        </Route>
        <Route exact path={`${path}/courses/course-details/:course_id`}>
          <CourseDetail />
        </Route>
      </Switch>
    </div>
  );
};

function mapStateToProps(state) {
  const panel_role = getUserPanelRole(state);
  return { panel_role };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(analyticsSummaryActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Insights);
