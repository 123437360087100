import { graphColors } from 'pages/dashboard/containers/monthlyActiveUsers/constants/colors';
import { userSettingsService } from 'services';

export const getPreparedGraphDataset = (maus_data) => {
  const dataSets = [];
  if (Array.isArray(maus_data)) {
    dataSets.push({
      label: 'Monthly Active User',
      fill: false,
      data: maus_data,
      backgroundColor: '#95969a',
      hoverBackgroundColor: '#dd1f25'
    });
  } else {
    let i = 0;
    for (let key in maus_data) {
      dataSets.push({
        label: key,
        fill: false,
        data: maus_data[key].monthly_users_count,
        backgroundColor: graphColors[i % graphColors.length]
      });
      i++;
    }
  }
  return dataSets;
};

export const addSubOrgParamValue = (payload, params) => {
  const { user_site, selected_sites } = payload;
  if (user_site.length) {
    if (selected_sites.includes('all')) {
      params['sub_org'] = user_site.map((item) => item.slug).join(',');
    } else {
      params['sub_org'] = selected_sites.join(',');
    }
  }
};

export const setupCsrfToken = () => {
  userSettingsService
    .getLmsCsrfToken()
    .then((sessionData) => {
      localStorage.setItem('lms_csrf_token', sessionData.data.csrf_token);
    })
    .catch((err) => {});

  userSettingsService
    .getPanelCsrfToken()
    .then((sessionData) => {
      localStorage.setItem('panel_csrf_token', sessionData.data.csrf_token);
    })
    .catch((err) => {});

  userSettingsService
    .getStudioCsrfToken()
    .then((sessionData) => {
      localStorage.setItem('studio_csrf_token', sessionData.data.csrf_token);
    })
    .catch((err) => {});

  userSettingsService
    .getEcommerceCsrfToken()
    .then((sessionData) => {
      localStorage.setItem('ecommerce_csrf_token', sessionData.data.csrf_token);
    })
    .catch((err) => {});
};

export const formatRole = (role) => {
  const words = role.split('_');
  const formattedRole = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  return formattedRole;
};

export const isSubdomainValid = (subDomain) => {
  const SPECIAL_CHARS_REGEX = /[^\w+\d+\s+]/g;
  return !Boolean(subDomain.match(SPECIAL_CHARS_REGEX));
};
