export const userSiteConstants = {
  REQUEST_USER_SITE_SUCCESS: 'REQUEST_USER_SITE_SUCCESS',
  REQUEST_USER_SITE_FAILURE: 'REQUEST_USER_SITE_FAILURE',
  UPDATE_SELECTED_SITES: 'UPDATE_SELECTED_SITES'
};

const requestUserSiteSuccess = (userData) => {
  return { type: userSiteConstants.REQUEST_USER_SITE_SUCCESS, payload: userData };
};

const requestUserSiteFailure = (error) => {
  return { type: userSiteConstants.REQUEST_USER_SITE_FAILURE, payload: error };
};

const UpdateSelectedSites = (data) => {
  return { type: userSiteConstants.UPDATE_SELECTED_SITES, payload: data };
};

export const userSite = {
  requestUserSiteSuccess,
  requestUserSiteFailure,
  UpdateSelectedSites
};
