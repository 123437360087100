import $ from 'jquery';
import { Store } from 'react-notifications-component';
import Cookies from 'js-cookie';

import { history } from 'helpers/history';
import { HTTP_PROTOCOL } from 'config';
import { settingsDispatches } from 'pages/settings/containers/settingsContainer/constants';
import { settingsActions } from 'pages/settings/containers/settingsContainer/actions';
import { HELP_BASE_URL, HUBSPOT_CHAT_SRC } from 'config';

export const configurePanelApp = (props) => {
  props.fetchServicesNotifications();
  props.fetchNotifications();
  props.fetchDropdownNotifications();
  props.getLmsCsrfToken();
  props.getStudioCsrfToken();
  props.getPanelCsrfToken();
  props.fetchMAUSQuickStats();
  props.fetchEnrolledCoursesQuickStats();
  props.fetchCourseCompletionsQuickStats();
  props.getAccountsSettings();
  props.getUserPreferences();

  if (props.panel_role === 'panel_admin' || props.panel_role === 'super_admin') {
    props.fetchUsers();
    props.fetchThemes();
  }
};

export const getHelpUrl = () => {
  return HELP_BASE_URL;
};

export const setOrganizationFromQueryParam = () => {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  const organization_url = searchParams.get('organization_url');

  if (organization_url !== null) {
    localStorage.setItem('organization_url', organization_url);
  }
};

export const getPanelBackendUrl = () => {
  const organization_url = localStorage.getItem('organization_url');
  if (organization_url !== null) {
    return organization_url;
  }

  let panelBackendDomain = window.location.host;
  if (panelBackendDomain.indexOf('apps.') > -1) {
    panelBackendDomain = panelBackendDomain.split('apps.');
  } else if (panelBackendDomain.indexOf('panel.') > -1) {
    panelBackendDomain = panelBackendDomain.split('panel.');
  }

  if (panelBackendDomain.length > 1) {
    panelBackendDomain = panelBackendDomain[1].replace('3030', '9999');
    return `${HTTP_PROTOCOL}panel.${panelBackendDomain}/`;
  }
};

export const getPanelLmsBaseUrl = () => {
  return $.trim(localStorage.getItem('panel_lms_base_url'));
};

export const getPanelOrganizationId = () => {
  return $.trim(localStorage.getItem('organization_id'));
};

export const getStudioBaseUrl = () => {
  return $.trim(localStorage.getItem('studio_base_url'));
};

export const getEcommerceBaseUrl = () => {
  return $.trim(localStorage.getItem('ecommerce_base_url'));
};

export const getWordpressBaseUrl = () => {
  return $.trim(localStorage.getItem('wordpress_base_url'));
};

export const getEdlyServiceUrl = (organization, service_key) => {
  let edlyServiceUrl = '/#';
  if (organization && organization.edly_services_urls && service_key in organization.edly_services_urls) {
    edlyServiceUrl = organization.edly_services_urls[service_key];
  }
  return edlyServiceUrl;
};

export const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    let cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = $.trim(cookies[i]);
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

export const setServicesNotificationsCookiesExpiry = (display_status) => {
  Cookies.set('panel_services_notifications_status', display_status, { path: '/' });

  let midnight = new Date();
  midnight.setHours(23, 59, 59, 0);
  Cookies.set('panel_services_notifications_daily_status', true, { expires: midnight, path: '/' });
};

export const messageNotification = (type, title, message, duration = 5000, onScreen = true) => {
  if (typeof message === 'string') {
    Store.addNotification({
      title: title,
      message: message,
      type: type,
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: duration,
        onScreen: onScreen
      }
    });
  }
};

export const removeSession = () => {
  localStorage.removeItem('edx_username');
};

export const validateLoginSession = (dispatch, error) => {
  if (!error.response) {
    return false;
  }

  let error_check = error.response.status === 403 || error.response.status === 401;
  let unauthorised = 'Authentication credentials were not provided.';

  if (error_check && unauthorised) {
    removeSession();
    dispatch(settingsDispatches.userSettingsFailure(error));

    if (error.response.status === 401) {
      history.push('/panel/login');
    }
    return true;
  }
};

export const validatePanelAccess = (panel_role) => {
  if (panel_role === 'panel_restricted') {
    history.push('/panel/401');
  }
};

export const yearsList = (min_year, max_year) => {
  return Array(max_year - min_year + 1)
    .fill()
    .map((_, index) => min_year + index)
    .reverse();
};

export const filterErrorMessages = (error) => {
  let error_messages = error.response.data.field_errors;
  return Object.keys(error_messages).map((key) => {
    return `${key.toUpperCase()}:${error_messages[key].user_message}`;
  });
};

export const setupOrganizationLocalStorage = (dispatch, organizations, setAcceptTermsConditions = true) => {
  const lmsUrlExists =
    organizations.data &&
    organizations.data.results &&
    organizations.data.results[0] &&
    organizations.data.results[0].edly_services_urls &&
    organizations.data.results[0].edly_services_urls.lms;
  if (lmsUrlExists) {
    let result = organizations.data.results[0];
    localStorage.setItem('panel_lms_base_url', result.edly_services_urls.lms);
    localStorage.setItem('edx_username', result.current_site_user);
    localStorage.setItem('organization_id', result.id);
    localStorage.setItem('organization_slug', result.slug);
    if (setAcceptTermsConditions) {
      localStorage.setItem('accept_terms_conditions', result.accept_terms_conditions);
    }

    let ecommerce_base_url = result.edly_services_urls.ecommerce;
    if (typeof ecommerce_base_url !== 'undefined' && ecommerce_base_url !== '') {
      localStorage.setItem('ecommerce_base_url', ecommerce_base_url);
    }

    let wordpressUrl = result.edly_services_urls.wordpress;
    if (typeof wordpressUrl !== 'undefined' && wordpressUrl !== '') {
      localStorage.setItem('wordpress_base_url', wordpressUrl);
    }

    let studioUrl = result.edly_services_urls.studio;
    if (typeof studioUrl !== 'undefined' && studioUrl !== '') {
      localStorage.setItem('studio_base_url', studioUrl);
    }

    dispatch(settingsActions.getPanelUserRoles());
  }
};

export const hubspotChat = () => {
  const script = document.createElement('script');
  script.id = 'hs-script-loader';
  script.type = 'text/javascript';
  script.src = HUBSPOT_CHAT_SRC;
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
};

export const LoopedinUpdatesWidget = () => {
  const script = document.createElement('script');
  script.src = 'https://cdn.productstash.io/js/popover.min.js?v=0.1';
  script.type = 'text/javascript';
  script.defer = 'defer';
  script.async = true;
  document.body.appendChild(script);

  const widget_attributes = document.createElement('script');
  widget_attributes.text = `var ps_popover = {
    workspace_id : "954e545d-2956-45d8-a5a5-18fd1dca944a",
    selector: ".loopedin-widget"
    };`;
  widget_attributes.type = 'text/javascript';
  widget_attributes.async = true;
  document.body.appendChild(widget_attributes);

  return <div className="loopedin-widget">What's New</div>;
};

export const convertValueTo2DP = (value) => {
  return isNaN(value) ? value : parseFloat(value).toFixed(2);
};

export const clearLocalStorage = () => {
  const keys = [
    'edx_username',
    'lms_csrf_token',
    'panel_csrf_token',
    'studio_csrf_token',
    'ecommerce_csrf_token',
    'organization_id',
    'organization_slug',
    'organization_url',
    'accept_terms_conditions',
    'panel_lms_base_url',
    'wordpress_base_url',
    'ecommerce_base_url',
    'studio_base_url'
  ];
  for (let key in keys) {
    localStorage.removeItem(key);
  }
};
