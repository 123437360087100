import { userSiteConstants } from 'pages/dashboard/containers/sites/constants';

const initialState = {
  user_site: [],
  selected_sites: ['all'],
  error: 'null'
};

export function UserSiteReducer(state = initialState, action) {
  switch (action.type) {
    case userSiteConstants.REQUEST_USER_SITE_SUCCESS:
      return {
        ...state,
        user_site: action.payload.data
      };
    case userSiteConstants.UPDATE_SELECTED_SITES: {
      let selectedSites = [];
      if (action.payload.removedSelectedSite === false) {
        selectedSites = [...state.selected_sites, action.payload.data];
        if (action.payload.data === 'all') {
          selectedSites = [action.payload.data];
        } else if (state.selected_sites.includes('all')) {
          selectedSites = selectedSites.filter((item) => item !== 'all');
        }
      } else {
        selectedSites = state.selected_sites.filter((item) => item !== action.payload.data);
        if (action.payload.data === 'all') {
          return state;
        }
      }
      if (selectedSites.length === 0) {
        selectedSites = ['all'];
      }
      return {
        ...state,
        user_site: [...state.user_site],
        selected_sites: [...selectedSites]
      };
    }
    case userSiteConstants.REQUEST_USER_SITE_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}
