import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import { getWordpressBaseUrl } from 'helpers';
import { RETIRED_EMAIL_PREFIX } from 'config';
import { getSiteUsers } from 'selectors';
import { usersActions } from 'pages/users/containers/usersContainer/actions';

const UserListItem = (props) => {
  const {
    username,
    user,
    selectedUsers,
    handleSelectedUsers,
    setUser,
    setEditModalShow,
    handlePasswordReset,
    passwordReset,
    dispatch,
    current_page,
    roles,
    userFilters,
    search
  } = props;
  const is_editable = !(user.is_superuser || user.username === username || user.email.startsWith(RETIRED_EMAIL_PREFIX));
  const [isStatusRequestInProgress, setIsStatusRequestInProgress] = useState(false);
  const [isBlockRequestInProgress, setIsBlockRequestInProgress] = useState(false);

  const handleUserStatusUpdate = (username, status, wordpress_role) => {
    setIsStatusRequestInProgress(true);
    dispatch(
      usersActions.updateUserStatus(
        username,
        status,
        search,
        wordpress_role,
        current_page,
        roles,
        setIsStatusRequestInProgress
      )
    );
  };

  const handleUserBlockStatusUpdate = (username, block_status) => {
    let orderBy = `${userFilters.column},${userFilters.direction}`;
    setIsBlockRequestInProgress(true);
    dispatch(
      usersActions.updateUserBlockStatus(
        username,
        block_status,
        search,
        current_page,
        roles,
        setIsBlockRequestInProgress,
        orderBy
      )
    );
  };

  return (
    <tr className={is_editable ? '' : 'disabled'}>
      <td className="col-check">
        <div
          className={`custom-control custom-checkbox ${
            user.is_superuser || user.email.startsWith(RETIRED_EMAIL_PREFIX) ? 'hidden' : ''
          }`}
        >
          <input
            type="checkbox"
            className="custom-control-input"
            id={user.id}
            name={user.username}
            value={user.username}
            checked={selectedUsers[user.username] === true}
            onChange={handleSelectedUsers}
          />
          <label className="custom-control-label" htmlFor={user.id}>
            <span className="sr-only">{user.username}</span>
          </label>
        </div>
      </td>
      <td data-title="Name " title={user.name}>
        <strong className="name">{user.name}&nbsp;</strong>
      </td>
      <td data-title="Username " title={user.username}>
        <span className="ellipsis username">{user.username}</span>
      </td>
      <td data-title="Email " title={user.email}>
        <span className="ellipsis email">{user.email}</span>
      </td>
      <td data-title="Status " className="status">
        {is_editable ? (
          <Fragment>
            <span
              title={user.is_active ? 'Deactivate User' : 'Activate User'}
              className={user.is_superuser || user.username === username ? 'hidden' : 'user-status'}
              onClick={() =>
                !isStatusRequestInProgress &&
                handleUserStatusUpdate(user.username, user.is_active !== true, user.wordpress_role)
              }
            >
              <FontAwesomeIcon icon={user.is_active ? Icons.faCheckCircle : Icons.faBan} />
            </span>
            <span
              title={user.is_blocked ? 'Unblock User' : 'Block User'}
              className={user.is_superuser || user.username === username ? 'hidden' : 'user-status'}
              onClick={() =>
                !isBlockRequestInProgress && handleUserBlockStatusUpdate(user.username, user.is_blocked !== true)
              }
            >
              <FontAwesomeIcon icon={user.is_blocked ? Icons.faUserSlash : Icons.faUser} />
            </span>
          </Fragment>
        ) : (
          '--'
        )}
      </td>
      <td className="roles" data-title="LMS Role ">
        {user.lms_role === 'super_admin' ? 'Site Admin' : user.lms_role.replace('_', ' ')}
      </td>
      {getWordpressBaseUrl() && (
        <td className="roles" data-title="Discovery Role">
          {user.wordpress_role.replace('_', ' ')}
        </td>
      )}
      <td className="roles" data-title="Panel Role ">
        {user.panel_role === 'insights_admin'
          ? 'Insights Viewer'
          : user.panel_role.replace('panel_', '').replace('_', ' ')}
      </td>
      <td>
        {is_editable && (
          <div className="actions">
            <span
              onClick={() => {
                setUser(user);
                setEditModalShow(true);
              }}
              title="Edit User"
              className={user.is_superuser ? 'invisible' : ''}
            >
              <FontAwesomeIcon icon={Icons.faPencilAlt} />
            </span>
            <span
              className={passwordReset ? '' : 'password-reset-disabled'}
              title="Reset Password"
              onClick={() => handlePasswordReset(user.email)}
            >
              <FontAwesomeIcon icon={Icons.faKey} />
            </span>
          </div>
        )}
      </td>
    </tr>
  );
};

function mapStateToProps(state) {
  const { current_page, roles, search } = getSiteUsers(state);
  return {
    current_page,
    roles,
    search
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(usersActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserListItem);
